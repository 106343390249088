import boatico from '../images/boatico2.svg';
import groupico from '../images/groupico.svg';
import capico from '../images/capico.svg';
import guideico from '../images/guideico.svg';
import wakesurfboardico from '../images/wakesurfboardico.svg';
import siuteico from '../images/siuteico.svg';
import wakeboardico from '../images/wakeboard-ico.svg';
import waterskiico from '../images/waterski-ico.svg';
import tubingico from '../images/tubing-ico.svg';
import table from '../images/table-ico.svg';

import mapico from '../images/map-ico.svg';
import wakesurfico from '../images/wakesurf-ico.svg';
import wakesurwhite from '../images/wakesurf-white.svg';
import wakeboardBoard from '../images/wakeboard-board-ico.svg';
import tea from '../images/tea-ico.svg';

import sale from '../images/hotsale-ico.svg';

import rocket from '../images/rocket-ico.svg';

import boaticoWhite from '../images/boatico-white.svg';
import groupicoWhite from '../images/group-ico-white.svg';
import capicoWhite from '../images/cap-ico-white.svg';
import tubingicoWhite from '../images/tubing-ico-white.svg';
import wakeboardWhite from '../images/wakeboard-ico-white.svg';

import eatsWhite from '../images/eats-ico-white.svg';
import waterskiicoWhite from '../images/waterski-ico-white.svg';
import bluetoothWhite from '../images/bluetooth-ico-white.svg';
import tableWhite from '../images/table-ico-white.svg';
import sunsetWhite from '../images/sunset-ico-white.svg';
import flowersWhite from '../images/flowers-ico-white.svg';
import treesWhite from '../images/trees-ico-white.svg';


import searay1 from '../images/crow1.webp';
import searay2 from '../images/searay2.webp';
import searay3 from '../images/searay3.webp';
import searay4 from '../images/searay4.webp';
import searay5 from '../images/crow2.webp';
import searay6 from '../images/crow3.webp';
import searay7 from '../images/searay7.webp';
import searay8 from '../images/searay8.webp';
import searay10 from '../images/searay1.webp';
import searay11 from '../images/searay10.webp';
import searay12 from '../images/searay12.webp';
import searay13 from '../images/crow4.webp';
import searay15 from '../images/searay15.webp';

import priceSearay from '../images/expensive-green.svg';
import groupSearay from '../images/groupsearay.svg';
import sportSearay from '../images/wakeboard-ico-green.svg';


import favourit2 from '../images/favourit2.webp';
import favourit3 from '../images/favourit3.webp';
import favourit4 from '../images/favourit4.webp';
import favourit5 from '../images/favourit5.webp';

import favourit7 from '../images/favourit7.webp';
import favourit8 from '../images/favourit8.webp';
import favourit9 from '../images/favourit9.webp';
import favourit10 from '../images/favourit11.webp';

import priceFavourit from '../images/cheap.svg';
import groupFavourit from '../images/groupfavourit.svg';
import sportFavourit from '../images/wakeboard-ico-red.svg';

import pricePerl from '../images/perlprice.svg';
import groupPerl from '../images/perlgroup.svg';
import sportPerl from '../images/perlsport.svg';

import priceGidr from '../images/pricegidric.svg';
import groupGidr from '../images/groupgidric.svg';
import driveGidr from '../images/gidrcap.svg';
import cardDriver from '../images/driver-card.svg';


import regal1 from '../images/regal1.webp';

import regal3 from '../images/regal3.webp';

import regal5 from '../images/regal5.webp';
import regal6 from '../images/regal6.webp';
import regal7 from '../images/regal7.webp';
import regal8 from '../images/regal8.webp';
import regal9 from '../images/regal9.webp';
import regal10 from '../images/regal10.webp';

import regal12 from '../images/regal12.webp';

import masterP from '../images/master.png';
import master1 from '../images/master1.webp';
import master2 from '../images/master2.webp';
import master3 from '../images/master3.webp';
import master4 from '../images/master4.webp';
import master5 from '../images/master5.webp';
import master10 from '../images/master10.webp';
import master11 from '../images/master12.webp';
import master12 from '../images/master13.webp';

import priceRegal from '../images/expensive-yellow.svg';
import groupRegal from '../images/groupregal.svg';
import sportRegal from '../images/wakesurf-ico-yellow.svg';

import accordionImg1 from '../images/searaybg.png';
import accordionImg2 from '../images/favouritbg.png';
import accordionImg3 from '../images/regalbg.png';
import accordionImg4 from '../images/aphaline.png';
import accordionImg5 from '../images/perl.png';
import cheapBlue from '../images/cheap-blue.svg';
import groupAphaline from '../images/group-aphaline.svg';
import noCaptain from '../images/captain-blue.svg';
import noRules from '../images/norules.svg';
import meals from '../images/meals.svg';
import music from '../images/music.svg';
import save from '../images/save.svg';
import driver from '../images/driver-card.svg';

import aphaline1 from '../images/aphaline.webp';
import aphaline2 from '../images/aphaline2.webp';
import aphaline3 from '../images/aphaline3.webp';
import aphaline4 from '../images/aphaline4.webp';
import aphaline5 from '../images/aphaline5.webp';
import aphaline6 from '../images/aphaline7.webp';
import alpha1 from '../images/alpha6.jpg';
import alpha2 from '../images/alpha7.jpeg';
import alpha3 from '../images/alpha9.jpeg';

import shalePrice from '../images/shaleprice.svg';
import shaleGroup from '../images/shaleGroup.svg';
import shaleH from '../images/shale.svg';
import shaleP from '../images/shale.png';
import shale1 from '../images/shale1.jpeg';
import shale2 from '../images/shale2.jpeg';
import shale3 from '../images/shale3.jpeg';
import shale4 from '../images/shale4.jpeg';
import shale5 from '../images/shale5.jpeg';
import shale6 from '../images/shale6.jpeg';
import shale7 from '../images/shale7.jpeg';
import shale8 from '../images/shale8.jpeg';

import perl1 from '../images/perl1.webp';
import perl2 from '../images/perl2.webp';
import perl3 from '../images/perl3.webp';
import perl4 from '../images/perl4.webp';

import sliderWind from '../images/slideWind.webp';


import comfort from '../images/comfort.webp';
import selfcaptain from '../images/selfcaptain.webp';
import wakeparty from '../images/wakeparty.webp';

import gidric1 from '../images/gidric.jpeg';
import gidric2 from '../images/gidric2.jpeg';
import gidric3 from '../images/gidric3.jpeg';
import gidric4 from '../images/gidric5.jpeg';
import gidric5 from '../images/gidric6.jpeg';

import mapCenter from '../images/ray1.webp';
import mapParusa from '../images/ray2.webp';
import mapBridge from '../images/ray3.webp';
import mapChanel from '../images/chanelmap.webp';
import mapWind from '../images/windmap.webp';

const wakesurfInfo = [
  {
    id: 0,
    title: 'Описание',
    desc: 'Вейксерфинг - это захватывающий набирающий популярность водный вид спорта. Он похож на традиционный серфинг, но вместо того, чтобы ловить волну в океане, катер создает волну на реке, по которой скользит райдер. Райдер стоит на доске для вейксерфинга и использует энергию волны для выполнения различных трюков и маневров.',
    tag: 'Это отличный способ испытать яркие эмоции от серфинга, не выезжая к океану.',
  },
  {
    id: 1,
    title: 'Что входит',
    include: [
      {
        name: 'Катер',
        ico: boatico,
      },
      {
        name: 'Вместимость 8 человек',
        ico: groupico,
      },
      {
        name: 'Капитан',
        ico: capico,
      },
      {
        name: 'Инструктаж',
        ico: guideico,
      },
      {
        name: 'Вейксерф',
        ico: wakesurfboardico,
      },
      {
        name: 'Жилеты и гидрокостюмы',
        ico: siuteico,
      },
    ],
  },
  {
    id: 2,
    title: 'Полезно знать',
    info: [
      {
        title: 'Что взять:',
        strings: [
          { string: 'Полотенце' },
          { string: 'Купальник' },
          { string: 'Напитки' },
          { string: 'Перекус' },
        ],
      },
      {
        title: 'Правила:',
        strings: [
          { string: 'услуга инструктора +1200 р/сет' },
          { string: 'уровень подготовки: неважен' },
          { string: 'предварительное бронирование' },
          { string: 'бесплатная отмена за 24 часа' },
        ],
      },
    ],
  },
];

const panels = [

  {
    label: 'Фавор',
    color: 'red',
    title: 'Аренда Катера',
    icons: [
      { img: priceFavourit },
      { img: groupFavourit },
      { img: sportFavourit },
    ],
    subtitle: 'С капитаном и без',
    content_title: 'Фавор',
    text_color: '#B44748',
    url: "https://w931545.yclients.com/widgetJS",
    content:
      'Отличный выбор для небольшой компании, которая ищет универсальную и практичную моторную лодку. Благодаря открытой носовой части с диваном, малому весу Фавор идеально подходит для драйвовых покатушек, рыбалки и водных видов спорта.',
    imgurl: accordionImg2,
    include: [
      {
        name: 'C капитаном и без',
        ico: capico,
      },
      {
        name: '5 гостей + кэп',
        ico: groupico,
      },

      {
        name: 'Вейкборд',
        ico: wakeboardico,
      },
      {
        name: 'Водные Лыжи',
        ico: waterskiico,
      },
      {
        name: 'Ватрушка',
        ico: tubingico,
      },
      {
        name: 'Долгопрудный',
        url: 'https://yandex.ru/maps/-/CCUOaJFNxD',
        ico: mapico,
      },
    ],
    prices: [
      {
        name: 'Будни',
        value: '6.000 р/ч',
      },
      {
        name: 'Выходные',
        value: '7.000 р/ч',
      },
      {
        name: '24 часа',
        value: 'от 30.000 р',
      },
      {
        name: 'Без капитана',
        value: 'Права ГИМС',
      },
    ],
    info: {
      title: 'Характеристики:',
      strings: [
        { string: 'Длина 5 метров' },
        { string: 'Ширина 2 метра' },
        { string: 'Мотор Suzuki 90 л.с' },
        { string: 'Своя музыка' },
        { string: 'Тент от дождя/солнца' },
        { string: 'Доставка по запросу' },
        { string: 'Отопитель салона' },
      ],
    },
    photos: [
       favourit10,
       
      favourit2,
      favourit5,
      favourit3,
      favourit4,
      
     
      favourit7,
      favourit8,    
      favourit9
    ],
  },
  {
    label: 'Кролик',
    color: 'blue',
    title: 'Аренда Катера',
    icons: [{ img: priceSearay }, { img: groupSearay }, { img: meals }],
    subtitle: 'С капитаном и без',
    text_color: '#292B51',
    content_title: 'Кролик',
    url: 'https://w904961.yclients.com/widgetJS',
    content:
      'Первоклассный катер, предлагающий комфортный отдых на реке. Элегантный дизайн, просторный салон, туалет на борту, вместительные каюты и мощные ходовые качества, Кролик идеально подходит для дня, проведенного на воде.',
    imgurl: accordionImg1,
    include: [
      {
        name: 'C капитаном и без',
        ico: capico,
      },
      {
        name: '7 гостей + кэп',
        ico: groupico,
      },

      {
        name: 'Обеденная зона',
        ico: table,
      },
      {
        name: 'Комфорт',
        ico: meals,
        },

      {
        name: 'Страховка',
        ico: save,
      },
      {
        name: 'Долгопрудный',
        url: 'https://yandex.ru/maps/-/CCUOaJFNxD',
        ico: mapico,
      },
    ],
    prices: [
      {
        name: 'Будни',
        value: '11.000 р/ч',
      },
      {
        name: 'Выходные',
        value: '13.000 р/ч',
      },
      {
        name: '24 часа',
        value: 'от 70.000 р',
        
      },

    ],
    info: {
      title: 'Характеристики:',
      strings: [
        { string: 'Длина 8 метров' },
        { string: 'Каюта в полный рост' },
        { string: 'Мотор 200 л.с.' },
        { string: 'Туалет' },
        { string: 'Мини кухня' },
        { string: 'Только с капитаном' },
        { string: 'Тент от дождя/солнца' },        
        { string: 'Отопитель салона' },
      ],
    },
    photos: [
       searay1 ,
       searay6 ,
       searay5,         
       searay13,     
    ],
  },
  {
    label: 'Альфа',
    color: 'lightblue',
    title: 'Аренда Катера',
    icons: [{ img: cheapBlue }, { img: groupAphaline }, { img: noCaptain }],
    subtitle: 'С капитаном и без',
    text_color: '#00a2d1',
    content_title: 'Альфа',
    content:
    'Катер не трубует прав для управления и предлагает комфортную палубу с мягкими удобными сидениями, мультимедийной системой и зарядным устройством USB. Раскройте в себе внутреннего капитана и погрузитесь в захватывающую водную прогулку .',
    imgurl: accordionImg4,
    include: [
    {
    name: 'Без капитана',
    ico: capico,
    },
    {
    name: '3 гостя',
    ico: groupico,
    },
    
    {
    name: 'Без прав',
    ico: noRules,
    },
    {
    name: 'Фуршет',
    ico: meals,
    },
    {
    name: 'Своя музыка',
    ico: music,
    },
    {
    name: 'Долгопрудный',
    url: 'https://yandex.ru/maps/-/CCUOaJFNxD',
    ico: mapico,
    },
    ],
    prices: [
    {
    name: 'Будни',
    value: '4.000 р',
    },
    {
    name: 'Выходные ',
    value: '5.000 р',
    },
    {
      name: '4 часа и более',
      value: 'скидка - 10%',
    },
    {
    name: 'Без капитана',
    value: 'Права не нужны',
    },

    ],
    info: {
    title: 'Характеристики:',
    strings: [
    { string: 'Катер афалина' },
    { string: 'Длина 4 метра' },
    { string: 'USB зарядка' },
    { string: 'Мотор 10 л.с.' },
    { string: 'До 25км/ч' },
    { string: 'Зона для загара' },
    { string: 'Пледы' },
    { string: 'Легко управляем' },
    ],
    },
    photos: [
      alpha1,
      aphaline5, 
      alpha2,
      alpha3,
      aphaline6,    
    ],
    },
    
  {
    label: 'Стар',
    color: 'green',
    title: 'Аренда Катера',
    icons: [
      { img: shalePrice },
      { img: shaleGroup },
      { img: shaleH },
    ],
    subtitle: 'С капитаном и без',
    content_title: 'Стар',
    text_color: '#47B48F',
    url: "",
    content:
      'Стар - прекрасный вариант для небольших компаний, которые нуждаются в универсальной и практичной моторной лодке. Ее открытая носовая часть с диваном и легкий вес делают ее идеальным выбором для динамичных покатушек, рыбалки или романтических прогулок',
    imgurl: shaleP,
    include: [
      {
        name: 'C капитаном и без',
        ico: capico,
      },
      {
        name: '6 гостей + кэп',
        ico: groupico,
      },

      {
        name: 'Своя музыка',
        ico: music,
      },
      // {
      //   name: 'Водные Лыжи',
      //   ico: waterskiico,
      // },
      // {
      //   name: 'Ватрушка',
      //   ico: tubingico,
      // },
      {
        name: 'Химки',
        url: 'https://yandex.ru/maps/-/CDVha2Zh',
        ico: mapico,
      },
    ],
    prices: [
      {
        name: 'Будни',
        value: '8.000 р/ч',
      },
      {
        name: 'Выходные',
        value: '10.000 р/ч',
      },
      {
        name: '4 и более часа',
        value: ' - 10%',
      },
      {
        name: 'Без капитана',
        value: 'Права ГИМС',
      },
    ],
    info: {
      title: 'Характеристики:',
      strings: [
        { string: 'Длина 6.5 метров' },
        { string: 'Ширина 2.2 метра' },
        { string: 'Мотор 150 л.с' },
        { string: 'Своя музыка' },
        { string: 'Тент от дождя/солнца' },
        { string: 'Эхолот' },
        { string: 'Отопитель салона' },
      ],
    },
    photos: [
      shale1,
      shale2,
      shale3,
      shale4,
      shale5,
      shale6,
      shale7,
      shale8
    ],
  },
  {
    label: 'Регал',
    color: 'yellow',
    title: 'Аренда Катера',
    icons: [{ img: priceRegal }, { img: groupRegal }, { img: sportRegal }],
    subtitle: 'С капитаном ',
    text_color: '#D9C108',
    url: "https://w931543.yclients.com/widgetJS",
    content_title: 'Регал',
    content:
      'Регал является превосходной базой для шумного отдыха большой компанией и водных видов спорта. Идеальный катер для любителей вейксерфа c передовой конструкцией корпуса, современными технологиями и вместительным салоном.',
    imgurl: accordionImg3,
    include: [
      {
        name: 'C капитаном',
        ico: capico,
      },
      {
        name: '7 гостей + кэп',
        ico: groupico,
      },
      {
        name: 'Вейксерф',
        ico: wakesurfico,
      },
      {
        name: 'Вейкборд',
        ico: wakeboardico,
      },
      {
        name: 'Водные Лыжи',
        ico: waterskiico,
      },
      {
        name: 'Долгопрудный',
        url:'https://yandex.ru/maps/-/CCUHRCwePD',
        ico: mapico,
      },
    ],
    prices: [
      {
        name: 'Будни',
        value: '9.000 р/ч',
      },
      {
        name: 'Выходные',
        value: '10.000 р/ч',
      },
      {
        name: '24 часа',
        value: '70.000 р',
      },
    ],
    info: {
      title: 'Характеристики:',
      strings: [
        { string: 'Вейк-буксировщик'},
        { string: 'Длина 7 метров' },
        { string: 'Ширина 2.5 метра' },
        { string: 'Мотор 350 л.с.' }, 
        { string: 'Музыкальная система'},
        { string: 'Гидрокостюмы'},
        { string: 'Доставка по запросу'},
      ],
    },
    photos: [
      regal1 ,    
      regal12,
      regal7 ,
      regal6,
     regal8,
     regal9,
     regal10,    
      regal5 ,
      regal3 ,
    ],
  },
 
  {
    label: 'Мастер Х',
    color: 'blue',
    title: 'Аренда Катера',
    icons: [{ img: priceRegal }, { img: groupRegal }, { img: sportRegal }],
    subtitle: 'С капитаном ',
    text_color: '#000000',
    content_title: 'Мастер Х',
    content:
      'Идеальный катер для любителей вейксерфа c передовой конструкцией корпуса, современными технологиями и вместительным салоном. Regal Session 22 обеспечивает комфортный ход и является превосходной базой для шумного отдыха большой компанией и водных видов спорта.',
    imgurl: masterP,
    include: [
      {
        name: 'C капитаном',
        ico: capico,
      },
      {
        name: '4 гостя + кэп',
        ico: groupico,
      },
      {
        name: 'Вейксерф',
        ico: wakesurfico,
      },
      {
        name: 'Вейкборд',
        ico: wakeboardico,
      },
      {
        name: 'Водные Лыжи',
        ico: waterskiico,
      },
      {
        name: 'Долгопрудный',
        url:'https://yandex.ru/maps/-/CCUsrEaEcD',
        ico: mapico,
      },
    ],
    prices: [
      {
        name: 'Будни',
        value: '10.000 р/ч',
      },
      {
        name: 'Выходные',
        value: '12.000 р/ч',
      },
      {
        name: 'Инструктор',
        value: '3.000 р/ч',
      },
      {
        name: '24 часа',
        value: '80.000 р',
      },
    ],
    info: {
      title: 'Характеристики:',
      strings: [
        { string: 'Вейк-буксировщик'},
        { string: 'Длина 7 метров' },
        { string: 'Ширина 2.5 метра' },
        { string: 'Мотор 350 л.с.' }, 
        { string: 'Музыкальная система'},
        { string: 'Гидрокостюмы'},
        { string: 'Доставка по запросу'},
      ],
    },
    photos: [
master1,
master10,
master11,
master2,
master3,
master4,
master5
    ],
  },

  {
    label: 'Гидрик',
    color: 'yellow',
    title: 'Аренда Катера',
    icons: [{ img: priceGidr }, { img: groupGidr }, { img: driveGidr }],
    subtitle: 'С капитаном ',
    text_color: '#D9C108',
    url: "https://w931543.yclients.com/widgetJS",
    content_title: 'Гидрик',
    content:
      'Гидроцикл SPARK 2UP 90  привлекает внимание благодаря яркому характеру и отличных технических характеристик. Несомненно, он является одним из наиболее востребованных среди любителей гидроциклов и тех, кто присматривает в качестве приобретения своего первого гидроцикла.',
    imgurl: accordionImg5,
    include: [
      {
        name: 'Без капитана',
        ico: capico,
      },
      {
        name: '2 гостя',
        ico: groupico,
      },    
      {
        name: 'Без прав',
        ico: noRules,
        },
      {
        name: 'Жилеты',
        ico: siuteico,
      },
      {
        name: 'Нагатинском затон',
        url:'https://yandex.ru/maps/-/CDrPmLOz',
        ico: mapico,
      },
    ],
    prices: [
 
      {
        name: '10 мин',
        value: '3.000 р',
      },
      {
        name: '20 мин',
        value: '6.000 р',
      },
      {
        name: '30 мин',
        value: '9.000 р',
      },
      // {
      //   name: 'Права ГИМС ',
      //   value: 'Гидроцикл',
      // },
    ],
    info: {
      title: 'Характеристики:',
      strings: [
        { string: 'SEADOO'},
        { string: 'Обучение перед экзаменом' },
        { string: ' до 80 км/ч' },
        { string: 'Мотор 90 л.с.' }, 
      ],
    },
    photos: [
      gidric4,
      gidric5,
      gidric1,
      gidric2,
      gidric3
    ],
  },
  {
    label: 'Альфа',
    color: 'lightblue',
    title: 'Аренда Катера',
    icons: [{ img: cheapBlue }, { img: groupAphaline }, { img: noCaptain }],
    subtitle: 'С капитаном и без',
    text_color: '#00a2d1',
    content_title: 'Альфа',
    content:
    'Катер не трубует прав для управления и предлагает комфортную палубу с мягкими удобными сидениями, мультимедийной системой и зарядным устройством USB. Раскройте в себе внутреннего капитана и погрузитесь в захватывающую водную прогулку .',
    imgurl: accordionImg4,
    include: [
    {
    name: 'Без капитана',
    ico: capico,
    },
    {
    name: '3 гостя',
    ico: groupico,
    },
    
    {
    name: 'Без прав',
    ico: noRules,
    },
    {
    name: 'Фуршет',
    ico: meals,
    },
    {
    name: 'Своя музыка',
    ico: music,
    },
    {
    name: 'Долгопрудный',
    url: 'https://yandex.ru/maps/-/CCUOaJFNxD',
    ico: mapico,
    },
    ],
    prices: [
    {
    name: 'Будни',
    value: '3.000 р/ч',
    },
    {
    name: 'Выходные',
    value: '4.000 р/ч',
    },
    {
    name: 'Без капитана',
    value: 'Права не нужны',
    },
    {
    name: 'Фуршет',
    value: '2.000 р/чел',
    },
    ],
    info: {
    title: 'Характеристики:',
    strings: [
    { string: 'Катер 2023 года' },
    { string: 'Длина 4 метра' },
    { string: 'USB зарядка' },
    { string: 'Мотор 10 л.с.' },
    { string: 'До 25км/ч' },
    { string: 'Зона для загара' },
    { string: 'Пледы' },
    { string: 'Легко управляем' },
    ],
    },
    photos: [
      aphaline1,
      aphaline5, 
      aphaline2,
      aphaline3,
      aphaline6,            
      aphaline4,
    
    ],
    },
     {
    label: 'Перл',
    color: 'purple',
    title: 'Аренда Катера',
    icons: [{ img: pricePerl }, { img: groupPerl }, { img: sportPerl }],
    subtitle: 'С капитаном ',
    text_color: '#B4477E',
    url: "https://w931543.yclients.com/widgetJS",
    content_title: 'Перл',
    content:
      'Откройте для себя воплощение стиля и динамики с помощью катера Перл. Этот элегантный и универсальный катер создан для того, чтобы поднять вашу летнюю водную прогулку на новый уровень.',
    imgurl: accordionImg5,
    include: [
      {
        name: 'C капитаном',
        ico: capico,
      },
      {
        name: '6 гостей + кэп',
        ico: groupico,
      },
      {
        name: 'Ватрушка',
        ico: tubingico,
      },
      {
        name: 'Вейкборд',
        ico: wakeboardico,
      },
    {
    name: 'Своя музыка',
    ico: music,
    },
      {
        name: 'Долгопрудный',
        url:'https://yandex.ru/maps/-/CTugV1t',
        ico: mapico,
      },
    ],
    prices: [
      {
        name: 'Будни',
        value: '6.000 р/ч',
      },
      {
        name: 'Выходные',
        value: '8.000 р/ч',
      },
      {
        name: '3 часа',
        value: 'Фуршет в подарок',
      },
    ],
    info: {
      title: 'Характеристики:',
      strings: [
        { string: 'Крутой капитан'},
        { string: 'Длина 6 метров' },
        { string: 'Ширина 2.2 метра' },
        { string: 'Мотор 250 л.с.' }, 
        { string: 'Музыкальная система'},
        { string: 'Каюта со столом'},
        { string: 'Холодильник'},
        { string: 'Зона для загара'},

      ],
    },
    photos: [
      perl1,
      perl2,
      perl3,
      perl4
    ],
  },
];

const wakeboardInfo = [
  {
    id: 0,
    title: 'Как добраться',
    url: [
      'https://yandex.ru/map-widget/v1/?um=constructor%3Aadb8bbdf7f1e0c23005360415eeb4c4deb2b847d6d2635de40c693d5290ab9b2&amp;source=constructor',
      'https://yandex.ru/map-widget/v1/?um=constructor%3A1b7269cb565ad9b029b871f543a12b8d06c4609e1b89d40a09fdf6d97e529947&amp;source=constructor',
      'https://yandex.ru/map-widget/v1/?um=constructor%3Aadb8bbdf7f1e0c23005360415eeb4c4deb2b847d6d2635de40c693d5290ab9b2&amp;source=constructor',
    ],
  },
  {
    id: 1,
    title: 'Что входит',
    include: [
      {
        name: 'Катер',
        ico: boatico,
      },
      {
        name: 'Вместимость',
        ico: groupico,
      },
      {
        name: 'Капитан',
        ico: capico,
      },
      {
        name: 'Инструктаж',
        ico: guideico,
      },
      {
        name: 'Вейкборд/Лыжи',
        ico: wakeboardBoard,
      },
      {
        name: 'Жилеты и гидрокостюмы',
        ico: siuteico,
      },
    ],
  },
  {
    id: 2,
    title: 'Полезно знать',
    info: [
      {
        title: 'Что взять:',
        strings: [
          { string: 'Полотенце' },
          { string: 'Купальник' },
          { string: 'Напитки' },
          { string: 'Перекус' },
        ],
      },
      {
        title: 'Правила:',
        strings: [
          { string: 'услуга инструктора +1200р/сет' },
          { string: 'уровень подготовки: неважен' },
          { string: 'предварительное бронирование' },
          { string: 'бесплатная отмена за 24 часа' },
        ],
      },
    ],
  },
 
];


const walksInfo = [
  // {
  //   id: 0,
  //   title: 'Прогулочка',
  //   price: '3.500 р',
  //   img: sliderMini,
  //   description:
  //     'В вашем распоряжении любой катер из нашего флота на 30 мин. Идеально подойдет тому, кто сомневается в выборе или хочет устроить небольшое приключение. Мы уверены, что наши лодки превзойдут ваши ожидания. ',
  //   include: [
  //     {
  //       name: 'Любой катер на 30 мин',
  //       ico: boaticoWhite,
  //     },
  //     {
  //       name: '5 гостей',
  //       ico: groupicoWhite,
  //     },
  //     {
  //       name: 'Водные лыжи',
  //       ico: waterskiicoWhite,
  //     },
  //     {
  //       name: 'Успеешь пофоткаться',
  //       ico: selfie,
  //     },
  //     {
  //       name: 'Чаек',
  //       ico: tea,
  //     },
  //     {
  //       name: 'Вейкборд',
  //       ico: wakeboardWhite,
  //     },
  //     {
  //       name: 'Капитан/Инструктор',
  //       ico: capicoWhite,
  //     },
  //     {
  //       name: 'Обогреватель',
  //       ico: heater,
  //     },
  //     {
  //       name: 'Только в мае',
  //       ico: sale,
  //     },
  //   ],
  // },
  {
    id: 0,
    title: 'Комфортная',
    boat: 'Кролик',
    price: 'Маршруты',
    img: comfort,
    maps: [
      mapCenter,
      mapParusa,
      mapBridge,
    
    ],
    photos: [     
      searay1 ,
      searay5 ,       
      
      searay3 ,
      searay8 ,
      searay4 ,      
      searay15,
      
      searay10,
      searay11,
    
      searay7 ,
      searay12,
    ],
    description:
      'Кролик благодаря своему элегантному дизайну, просторному салону и удобствам на борту, предлагает непревзойденный комфорт для прогулки по реке. Будь то день рождения, корпоративное мероприятие или просто встреча друзей, устройте незабываемый день на воде.',
    include: [
      {
        name: 'Фото Катера Кролик',
        ico: boaticoWhite,
      },
      {
        name: '8 гостей',
        ico: groupicoWhite,
      },
      {
        name: 'Фуршет',
       ico: eatsWhite,
     },
      {
        name: 'Романтическая атмосфера',
        ico: sunsetWhite,
      },   

      {
        name: 'Обеденная зона',
        ico: tableWhite,
      },

      {
        name: 'Своя музыка',
        ico: bluetoothWhite,
      },
      {
        name: 'Живописные маршруты',
        ico: treesWhite,
      },
      {
        name: 'Капитан',
        ico: capicoWhite,
      },

  {
         name: 'от 8000 р/ч',
         ico: sale,
      },

    ],
  },
  // {
  //   id: 0,
  //   title: 'Скоростная',
  //   boat: 'Кролик',
  //   price: 'Подробнее',
  //   img: sliderTower,
  //   photos: [
  //     mapBridge,
  //     searay1 ,
  //     searay6 ,
  //     searay5 ,         
      
  //     searay3 ,
      
  //     searay4 ,      
  //     searay15,
      
  //     searay10,
  //     searay11,
  //     searay8 ,
  //     searay7 ,
  //     searay12,
  //   ],
  //   description:
  //     'Полюбуйтесь неовизантийским величием Никольского собора, футуристическим Домом правительства Московской области и пленитесь красотой уникального Живописного моста на нашей яхте Кролик. Ощутите идеальное сочетание истории, природы и современных технологий!',
  //   include: [
  //     {
  //       name: 'Кролик на 3 часа',
  //       ico: boaticoWhite,
  //     },
  //     {
  //       name: '8 гостей',
  //       ico: groupicoWhite,
  //     },
  //     {
  //       name: 'Вейкборд',
  //       ico: wakeboardWhite,
  //     },
  //     {
  //       name: 'Романтическая атмосфера',
  //       ico: sunsetWhite,
  //     },
   

  //     {
  //       name: 'Обеденная зона',
  //       ico: tableWhite,
  //     },

  //     {
  //       name: 'Своя музыка',
  //       ico: bluetoothWhite,
  //     },
  //     {
  //       name: 'Живописный маршрут',
  //       ico: treesWhite,
  //     },
  //     {
  //       name: 'Капитан',
  //       ico: capicoWhite,
  //     },

  // {
  //        name: 'Новинка',
  //        ico: sale,
  //     },

  //   ],
  // },
 
  {
    id: 1,
    title: 'С ветерком',
    price: 'Маршруты',
    boat: 'Фавор',
    img: sliderWind,
    maps: [
      mapWind,
      mapChanel    
    ],
    photos: [     
      favourit10,       
      favourit2,
      
      favourit3,
      favourit4,  
      favourit5,
   
      favourit8,   
    
    ],
    description:
      'Испытайте невероятный восторг от скорости во время прогулки на катере Фавор. Обладая впечатляющими скоростными возможностями, эта лодка пронесет вас по воде, подарив захватывающее приключение, наполненное адреналином.',
    include: [
      {
        name: 'Фото Катера Фавор',
        ico: boaticoWhite,
      },
      {
        name: '5 гостей',
        ico: groupicoWhite,
      },
      {
        name: 'Водные лыжи',
        ico: waterskiicoWhite,
      },
      {
        name: 'Живописные маршруты',
        ico: treesWhite,
      },
      {
        name: 'Тюбинг',
        ico: tubingicoWhite,
      },

      {
        name: 'Вейкборд',
        ico: wakeboardWhite,
      },
      {
        name: 'Капитан/Инструктор',
        ico: capicoWhite,
      },
      {
        name: 'Своя музыка',
        ico: bluetoothWhite,
      },
      {
        name: 'от 6000 р/ч',
        ico: sale,
      },
    ],
  },  
  {
    id: 2,
    title: 'Сам себе капитан',
    price: 'от 4000 р/ч',
    boat: 'Альфа',
    img: selfcaptain,
    maps: [
      aphaline4 
    ],
    photos: [
    aphaline1,
    aphaline2,
    aphaline3,
    aphaline4 
    
    ],
    description:
      'Раскройте в себе внутреннего капитана и погрузитесь в захватывающую водную прогулку. Исследуйте живописные красоты Химкинского и Клязьминского водохранилищ или отправляйтесь на Северный речной вокзал.',
    include: [
      {
        name: 'Фото Катера Альфа',
        ico: boaticoWhite,
      },
      {
        name: '3 гостя',
        ico: groupicoWhite,
      },
      {
        name: 'Фуршет',
       ico: eatsWhite,
     },
      {
        name: 'Романтическая атмосфера',
        ico: sunsetWhite,
      }, 

      {
        name: 'Уютно',
         ico: tea,
       },
      {
        name: 'Для свидания',
        ico: flowersWhite,
      },
      {
        name: 'Без прав без капитана',
        ico: capicoWhite,
      },
      {
        name: 'Своя музыка',
        ico: bluetoothWhite,
      },
      {
        name: 'от 3000 р/ч',
        ico: sale,
      },
    ],
  }, 
  {
    id: 3,
    title: 'Вейксерф пати',
    price: 'О Катере',
    boat: 'Мастер Х',
    img: wakeparty,
    maps: [     
      master10,
      master11,
      master1, 
      master12,
      master3,   
    ],
    photos: [
      master1,    
      master10,
      master11,
      master12,
      master3,
    ],
    description:
      'Отправляйтесь в захватывающее приключение с вейксерфингом на борту нашего катера Мастер Х по акватории Пироговского водохранилища. Для тех, кто хочет поймать несколько речных волн или устроить шумную вечеринку на воде.',
    include: [
      {
        name: 'Фото катера',
        ico: boaticoWhite,
      },
      {
        name: '8 гостей',
        ico: groupicoWhite,
      },
      {
        name: 'Вейкборд',
       ico: wakeboardWhite,
     },
     {
       name: 'Пироговское вдхр',
       ico: treesWhite,
      },

      {
         name: 'Скоростной',
         ico: rocket,
       },
      {
        name: 'Вейксерф',
        ico: wakesurwhite,
      },
      {
        name: 'Капитан/Инструктор',
        ico: capicoWhite,
      },
      {
        name: 'Своя музыка',
        ico: bluetoothWhite,
      },
      {
        name: 'от 9000 р/ч',
        ico: sale,
      },
    ],
  },   
  // {
  //   id: 2,
  //   title: 'Центр Москвы',
  //   boat: 'Кролик',
  //   price: 'Подробнее',
  //   img: sliderCenter,
  //   description:
  //     'Погрузитесь в сердце величественной красоты Москвы на нашей яхте Кролик. Проплывите по легендарной Москве-реке и станьте свидетелем величия столицы и ее канонических достопримечательностей на прогулке "Центр Москвы".',
  //     photos: [
  //       mapCenter,
  //       searay1 ,
  //       searay6 ,
  //       searay5 ,         
        
  //       searay3 ,
        
  //       searay4 ,      
  //       searay15,
        
  //       searay10,
  //       searay11,
  //       searay8 ,
  //       searay7 ,
  //       searay12,
  //     ],
  //     include: [
  //     {
  //       name: 'Кролик на 6-8 часов',
  //       ico: boaticoWhite,
  //     },
  //     {
  //       name: '8 гостей',
  //       ico: groupicoWhite,
  //     },
  //     {
  //       name: 'Шлюзование',
  //       ico: gateWhite,
  //     },
  //     {
  //       name: 'Романтическая атмосфера',
  //       ico: sunsetWhite,
  //     },
   

  //     {
  //       name: 'Обеденная зона',
  //       ico: tableWhite,
  //     },

  //     {
  //       name: 'Своя музыка',
  //       ico: bluetoothWhite,
  //     },
  //     {
  //       name: 'Живописный маршрут',
  //       ico: treesWhite,
  //     },
  //     {
  //       name: 'Капитан',
  //       ico: capicoWhite,
  //     },

  // {
  //        name: 'Новинка',
  //        ico: sale,
  //     },

  //   ],
  // },
  // {
  //   id: 3,
  //   title: 'Канал им. Москвы',
  //   boat: 'Фавор',
  //   price: 'Подробнее',
  //   img: sliderChanel,
  //   photos: [
  //     mapChanel,
  //     favourit10,       
  //     favourit2,
      
  //     favourit3,
  //     favourit4,  
  //     favourit5,
   
  //     favourit8,   
    
  //   ],
  //   description:
  //     'Отправляйтесь в незабываемую прогулку на катере Фавор по живописному каналу им. Москвы и исследуйте его окрестности. Проплывая мимо Северного речного вокзала и уникального экраноплана "Орленок", направимся до Химкинского вдхр.',
  //   include: [
  //     {
  //       name: 'Фавор на 2 часа',
  //       ico: boaticoWhite,
  //     },
  //     {
  //       name: '5 гостей',
  //       ico: groupicoWhite,
  //     },
  //     {
  //       name: 'Водные лыжи',
  //       ico: waterskiicoWhite,
  //     },
  //     {
  //       name: 'Живописный маршрут',
  //       ico: treesWhite,
  //     },
  //     {
  //       name: 'Чаек',
  //       ico: tea,
  //     },
  //     {
  //       name: 'Вейкборд',
  //       ico: wakeboardWhite,
  //     },
  //     {
  //       name: 'Капитан/Инструктор',
  //       ico: capicoWhite,
  //     },
  //     {
  //       name: 'Своя музыка',
  //       ico: bluetoothWhite,
  //     },
  //     {
  //       name: 'Тюбинг',
  //       ico: tubingicoWhite,
  //     },

  //   ],
  // },
  // {
  //   id: 4,
  //   title: 'Алые паруса',
  //   boat: 'Кролик',
  //   price: 'Подробнее',
  //   img: sliderParusa,
  //   photos: [
  //     mapParusa,
  //     searay1 ,
  //     searay6 ,
  //     searay5 ,         
      
  //     searay3 ,
      
  //     searay4 ,      
  //     searay15,
      
  //     searay10,
  //     searay11,
  //     searay8 ,
  //     searay7 ,
  //     searay12,
  //   ],
  //   description:
  //     'Взгляните на символ премиальной недвижимости, который изящно раскинулся вдоль берега Москвы реки. Погрузитесь в чарующий пейзаж и отправляйтесь в путешествие, сочетающее в себе элегантность, спокойствие и роскошь на нашей яхте Кролик.',
  //   include: [
  //     {
  //       name: 'Кролик на 2 часа',
  //       ico: boaticoWhite,
  //     },
  //     {
  //       name: '8 гостей',
  //       ico: groupicoWhite,
  //     },
  //     {
  //       name: 'Вейкборд',
  //       ico: wakeboardWhite,
  //     },
  //     {
  //       name: 'Романтическая атмосфера',
  //       ico: sunsetWhite,
  //     },
   

  //     {
  //       name: 'Обеденная зона',
  //       ico: tableWhite,
  //     },

  //     {
  //       name: 'Своя музыка',
  //       ico: bluetoothWhite,
  //     },
  //     {
  //       name: 'Скоростной',
  //       ico: rocket,
  //     },
  //     {
  //       name: 'Капитан',
  //       ico: capicoWhite,
  //     },

  // {
  //        name: 'Новинка',
  //        ico: sale,
  //     },

  //   ],
  // },
 
  // {
  //   id: 5,
  //   title: 'Свидание',
  //   price: 'от 15.000р',
  //   boat: 'Фавор',
  //   img: sliderDate,
  //   photos: [     
  //     favourit10,       
  //     favourit2,
      
  //     favourit3,
  //     favourit4,  
  //     favourit5,
   
  //     favourit8,   
    
  //   ],
  //   description:
  //     'Ищете уникальную и романтическую идею для свидания? Насладитесь безмятежной атмосферой и потрясающими пейзажами вместе со своим близким человеком. Позвольте нам позаботиться о деталях и сделать вашу встречу незабываемой.',
  //   include: [
  //     {
  //       name: 'Фавор на 2 часа',
  //       ico: boaticoWhite,
  //     },
  //     {
  //       name: '4 гостя',
  //       ico: groupicoWhite,
  //     },
  //     {
  //       name: 'Водные лыжи',
  //       ico: waterskiicoWhite,
  //     },
  //     {
  //       name: 'Романтическая атмосфера',
  //       ico: sunsetWhite,
  //     },
  //     {
  //       name: 'Своя музыка',
  //       ico: bluetoothWhite,
  //     },
  //     {
  //       name: 'Вейкборд',
  //       ico: wakeboardWhite,
  //     },
  //     {
  //       name: 'Капитан/Инструктор',
  //       ico: capicoWhite,
  //     },
  //     {
  //       name: 'Фуршет',
  //       ico: eatsWhite,
  //     },
  //     {
  //       name: 'Цветы',
  //       ico: flowersWhite,
  //     },
  //   ],
  // },
  // {
  //   id: 6,
  //   title: 'День рождения',
  //   price: '30.000 р',
  //   boat: 'Кролик',
  //   img: sliderBirthday,
  //   photos: [     
  //     searay1 ,
  //     searay6 ,
  //     searay5 ,         
      
  //     searay3 ,
      
  //     searay4 ,      
  //     searay15,
      
  //     searay10,
  //     searay11,
  //     searay8 ,
  //     searay7 ,
  //     searay12,
  //   ],
  //   description:
  //     'Вечеринка на нашем катере Кролик станет запоминающимся событием для всех. Собирайте своих друзей и близких и отправляйтесь на воду, чтобы провести день рождения оригинально, весело и солнечно!',
  //   include: [
  //     {
  //       name: 'Кролик на 3 часа',
  //       ico: boaticoWhite,
  //     },
  //     {
  //       name: '10 гостей',
  //       ico: groupicoWhite,
  //     },
  //     {
  //       name: 'Водные лыжи',
  //       ico: waterskiicoWhite,
  //     },
  //     {
  //       name: 'Праздничное оформление',
  //       ico: baloonsWhite,
  //     },
  //     {
  //       name: 'Своя музыка',
  //       ico: bluetoothWhite,
  //     },
  //     {
  //       name: 'Вейкборд',
  //       ico: wakeboardWhite,
  //     },
  //     {
  //       name: 'Капитан/Инструктор',
  //       ico: capicoWhite,
  //     },
  //     {
  //       name: 'Фуршет',
  //       ico: eatsWhite,
  //     },
  //     {
  //       name: 'Обеденная зона',
  //       ico: tableWhite,
  //     },
  //   ],
  // },
  
  // {
  //   id: 6,
  //   title: 'День на воде',
  //   price: '50.000 р',
  //   boat: 'Кролик',
  //   img: sliderDay,
  //   description:
  //     'Проведите незабываемый день на воде со своими близкими. Насладитесь круизом по живописным местам, катанием на водных лыжах и обедом на реке. Наш дружелюбный и опытный экипаж обеспечит вашу безопасность и комфорт, сделав ваш день на воде незабываемым.',
  //   include: [
  //     {
  //       name: 'Кролик на 6 часов',
  //       ico: boaticoWhite,
  //     },
  //     {
  //       name: '10 гостей',
  //       ico: groupicoWhite,
  //     },
  //     {
  //       name: 'Водные лыжи',
  //       ico: waterskiicoWhite,
  //     },
  //     {
  //       name: 'Живописный маршрут',
  //       ico: treesWhite,
  //     },
  //     {
  //       name: 'Своя музыка',
  //       ico: bluetoothWhite,
  //     },
  //     {
  //       name: 'Вейкборд',
  //       ico: wakeboardWhite,
  //     },
  //     {
  //       name: 'Капитан/Инструктор',
  //       ico: capicoWhite,
  //     },
  //     {
  //       name: 'Обед',
  //       ico: lunchWhite,
  //     },
  //     {
  //       name: 'Тюбинг',
  //       ico: tubingicoWhite,
  //     },
  //   ],
  // },

  // {
  //   id: 7,
  //   title: 'Мальчишник/Девичник',
  //   price: '30.000 р',
  //   boat: 'Кролик',
  //   img: sliderParty,
  //   photos: [     
  //     searay1 ,
  //     searay6 ,
  //     searay5 ,         
      
  //     searay3 ,
      
  //     searay4 ,      
  //     searay15,
      
  //     searay10,
  //     searay11,
  //     searay8 ,
  //     searay7 ,
  //     searay12,
  //   ],
  //   description:
  //     'Ищете уникальный и запоминающийся способ отпраздновать предстоящую свадьбу с самыми близкими друзьями? Проведите вечеринку на воде, наслаждаясь такими развлечениями, как вейкбординг, водные лыжи и тюбинг, а завершите вечер веселым круизом с ужином.',
  //   include: [
  //     {
  //       name: 'Кролик на 3 часа',
  //       ico: boaticoWhite,
  //     },
  //     {
  //       name: '10 гостей',
  //       ico: groupicoWhite,
  //     },
  //     {
  //       name: 'Водные лыжи',
  //       ico: waterskiicoWhite,
  //     },
  //     {
  //       name: 'Праздничное оформление',
  //       ico: baloonsWhite,
  //     },
  //     {
  //       name: 'Своя музыка',
  //       ico: bluetoothWhite,
  //     },
  //     {
  //       name: 'Вейкборд',
  //       ico: wakeboardWhite,
  //     },
  //     {
  //       name: 'Капитан/Инструктор',
  //       ico: capicoWhite,
  //     },
  //     {
  //       name: 'Фуршет',
  //       ico: eatsWhite,
  //     },
  //     {
  //       name: 'Тюбинг',
  //       ico: tubingicoWhite,
  //     },
  //   ],
  // },
];

const faq = [
  {
    title: 'Могу ли я принести свою еду и напитки?',
    description:
      'Да, вы можете приносить свою еду и напитки.',
  },
  {
    title: 'Не умею кататься. У меня получится?',
    description:
      'Да, мы приветствуем любой уровень мастерства и предоставляем услуги инструктора.',
  },
  {
    title: 'Возможна ли корректировка длительности прогулки?',
    description:
      'Да, вы можете выбрать любую длительность прогулки, свяжитесь с нами для уточнения деталей.',
  },
  {
    title: 'Могу ли я принести свое собственное оборудование?',
    description:
      'Да, вы можете принести свой вейкборд, водные лыжи или доску для вейксерфинга. Однако если у вас нет собственного оборудования, мы предоставим его.',
  },
  {
    title: 'Обязательно бронировать?',
    description:
      'Мы настоятельно рекомендуем бронировать заранее, чтобы убедиться в наличии свободных мест. Вы можете забронировать катер онлайн или позвонив нам.',
  },
  {
    title: 'Есть ли скидки?',
    description:
      'Да, мы предлагаем 10% скидку для бронирования свыше 3х часов. Для посуточного бронирования производится индивидуальный расчет. Свяжитесь с нами для получения дополнительной информации.',
  },
  {
    title: 'Можно ли доставить катер за город?',
    description:
      'Да, мы всегда готовы помочь организовать мероприятие за городом. Пожалуйста, свяжитесь с нами для получения дополнительной информации.',
  },
  {
    title: 'Каковы ваши правила отмены бронирования?',
    description:
      'Вы можете перенести запись на другое время за 24 часа до начала.',
  },
];

const contactsInfo = 
  {
    email: 'riverflou@yandex.ru',
    phone: '+7 915 181 48 00',
    locations: [
      {
        name: 'Яхт Клуб Спартак',
        value:'Набережная улица, 4А',
        url:'https://yandex.ru/profile/148165022453'
      },
      // {
      //   name: 'Фавор, Альфа',
      //   value:'Яхт Клуб Спартак',
      //   url:'https://yandex.ru/maps/?um=constructor%3A01db7b85243c30c4037e49a2b993f6ff8e288daa566de90bc6126a8865c32e6d&source=constructorLink'
      // },
      // {
      //   name: 'Вейксерф',
      //   value:'Пироговское вдхр',
      //   url:'https://yandex.ru/maps/?um=constructor%3A44b8c8fa62fee959ab02b6f6897b0a1fc1fc956901805ab10ccc8cf9ffbaf779&source=constructorLink'
      // },
    ],
  };

  
  // const events = [
  //   {
  //     date: 1992,
  //     image: eventFirst,
  //     content:
  //       "Создание первых автоматизированных рабочих мест (АРМ) для сотрудников отделов кадров, технических отделов, групп учета, нарядчиков локомотивных депо. Установка первых персональных компьютеров на предприятия Московской железной дороги и Московского метрополитена, обучение первых пользователей ПК.",
  //   },
  //   {
  //     date: 1998,
  //     image: eventSecond,
  //     content:
  //       "Формирование платформы для разработки автоматизированных систем – Проект «М». Разработана универсальная платформа в технологии «клиент-сервер», на базе которой было проведено внедрение ERP собственной разработки на Московском метрополитене, в Пожарной охране Московской области, пейджинговой компании «Мобил Телеком».",
  //   },
  //   {
  //     date: 2000,
  //     image: eventThird,
  //     content:
  //       "Первые разработки учебных и информационных систем с использованием 3D моделирования, интерактивных моделей работы узлов, пневматических и электрических схем на основе Flash-анимации.",
  //   },
  //   {
  //     date: 2004,
  //     image: eventFourth,
  //     content:
  //       "Внедрение программной среды MBuilder для создания и дистрибуции интерактивных электронных технических руководств (ИЭТР) с поддержкой интерактивных иерархических 3D моделей изделий и узлов, интерактивной 3D анимации, других средств интерактивной визуализации для предприятий инфраструктуры РЖД и метрополитенов.",
  //   },
  //   {
  //     date: 2006,
  //     image: eventReestr,
  //     content:
  //       "«Комплексная автоматизированная система паспортизации аппаратуры АТДП (АСУ ремонта аппаратуры)» - первое свидетельство об официальной регистрации программы для ЭВМ полученное ЦТИ .",
  //   },
  //   {
  //     date: 2012,
  //     image: eventFifth,
  //     content:
  //       "Модернизация платформы MBuilder2, предназначенной, наряду с функционалом создания и представления пользователю ИЭТР, для формирования специалистами заказчика технологических процессов обслуживания и ремонта, другой структурированной технологической документации. ",
  //   },
  //   {
  //     date: 2014,
  //     image: eventSixth ,
  //     content:
  //       "Интеграция Комплексной системы поддержки технологии ремонта локомотивов на сети предприятий ТМХ-Сервис. Более 200 обученных пользователей, несколько тысяч созданных технологических процессов. ",
  //   },
  //   {
  //     date: 2015,
  //     image: eventSeventh,
  //     content:
  //       "Начало сотрудничества с локомотивостроительными и вагоностроительными заводами Трансмашхолдинг, за прошедшие с 2013 годы созданы интерактивные каталоги и ИЭТР по эксплуатации, обслуживанию и ремонту тепловозов Луганского и Брянского заводов, пассажирских вагонов и МВПС Тверского завода, электровозов НЭВЗ. ",
  //   },
  //   {
  //     date: 2019,
  //     image: eventEighth,
  //     content:
  //       "Внедрение технологии ведения актуализированных каталогов. Учет извещений об изменении конструкции, представление каталога «как выпущено» для каждого экземпляра изделия, в соответствии с актуальным состоянием конструкторской документации.",
  //   },
  //   {
  //     date: 2020,
  //     image: eventNineth,
  //     content:
  //       "Применение VR технологий для обучения персонала выполнению технологических операций. В VR тренажере предусмотрен функционал индивидуального обучения, приема экзамена, мониторинга знаний.",
  //   },
  //   {
  //     date: 2021,
  //     image: eventTenth,
  //     content:
  //       "Создание формата представления и инструмента разработки Интерактивных стандартных операционных карт сборки (ИСОК). Разработка мультиязычного функционала и модульной структуры ИЭТР, которые позволяют оперативно адаптировать контент для пользователей предприятий рельсового транспорта из разных государств.",
  //   },
  // ];

export { wakesurfInfo, panels, wakeboardInfo, walksInfo, faq, contactsInfo };
