import React from "react";

function Geo({color}) {
  return (    
<svg className='panel__include-ico' width="28" height="36" viewBox="0 0 28 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.0001 0.333252C19.5167 0.333252 24.0001 4.76658 24.0001 10.2499C24.0001 17.6833 14.0001 28.6666 14.0001 28.6666C14.0001 28.6666 4.00008 17.6833 4.00008 10.2499C4.00008 4.76658 8.48341 0.333252 14.0001 0.333252ZM14.0001 6.99992C13.116 6.99992 12.2682 7.35111 11.6431 7.97623C11.0179 8.60135 10.6667 9.4492 10.6667 10.3333C10.6667 11.2173 11.0179 12.0652 11.6431 12.6903C12.2682 13.3154 13.116 13.6666 14.0001 13.6666C14.8841 13.6666 15.732 13.3154 16.3571 12.6903C16.9822 12.0652 17.3334 11.2173 17.3334 10.3333C17.3334 9.4492 16.9822 8.60135 16.3571 7.97623C15.732 7.35111 14.8841 6.99992 14.0001 6.99992ZM27.3334 28.6666C27.3334 32.3499 21.3667 35.3333 14.0001 35.3333C6.63341 35.3333 0.666748 32.3499 0.666748 28.6666C0.666748 26.5166 2.70008 24.5999 5.85008 23.3833L6.91675 24.8999C5.11675 25.6499 4.00008 26.6833 4.00008 27.8333C4.00008 30.1333 8.48341 31.9999 14.0001 31.9999C19.5167 31.9999 24.0001 30.1333 24.0001 27.8333C24.0001 26.6833 22.8834 25.6499 21.0834 24.8999L22.1501 23.3833C25.3001 24.5999 27.3334 26.5166 27.3334 28.6666Z" fill={color}/>
</svg>

  );
}

export default Geo;
