const wakesurfPrice = [
  {
    id: '1',
    boat: 'Мастер Х',
    color:'#000000',
    prices: [
      {
        name: 'Сет',
        value: '25 минут',
      },
      {
        name: 'Будни',
        value: '4500 р/сет',
      },
      {
        name: 'Выходные',
        value: '5000 р/сет',
      },
    ],
    location: {
      name: 'Пироговское',
      value:'Санаторий Березки',
      url:'https://yandex.ru/maps/-/CCUKnNtGXA'
    }
  },
];


const wakeboardPrice = [
  {
    id: '1',
    boat: 'Фавор',
    color:'#B44748',
    prices: [

      {
        name: 'Будни',
        value: '6.000 р/час',
      },
      {
        name: 'Выходные',
        value: '7.000 р/час',
      },
      {
        name: 'Инструктор',
        value: '2.400 р/час',
      },
    ],
    location: {
      name: 'Долгопрудный',
      value:'Яхт Клуб Спартак',
      url:'https://yandex.ru/maps/-/CCUOaJFNxD'
    }
  },  

];

export  {wakesurfPrice, wakeboardPrice};
